/**
 * @license
 *
 * Font Family: Switzer
 * Designed by: Jérémie Hornus
 * URL: https://www.fontshare.com/fonts/switzer
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Switzer Variable(Variable font)
 * Switzer Variable Italic(Variable font)
 * Switzer Thin
 * Switzer Thin Italic
 * Switzer Extralight
 * Switzer Extralight Italic
 * Switzer Light
 * Switzer Light Italic
 * Switzer Regular
 * Switzer Italic
 * Switzer Medium
 * Switzer Medium Italic
 * Switzer Semibold
 * Switzer Semibold Italic
 * Switzer Bold
 * Switzer Bold Italic
 * Switzer Extrabold         
 * Switzer Extrabold Italic
 * Switzer Black
 * Switzer Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
    font-family: 'Switzer-Variable';
    src: url('/fonts/switzer/Switzer-Variable.woff2') format('woff2'),
        url('/fonts/switzer/Switzer-Variable.woff') format('woff'),
        url('/fonts/switzer/Switzer-Variable.ttf') format('truetype');
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
  }
  
  
  /**
  * This is a variable font
  * You can controll variable axes as shown below:
  * font-variation-settings: 'wght' 400.0;
  *
  * available axes:
  
  * 'wght' (range from 100.0 to 900.0)
  
  */
  
  @font-face {
    font-family: 'Switzer-VariableItalic';
    src: url('/fonts/switzer/Switzer-VariableItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-VariableItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-VariableItalic.ttf') format('truetype');
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Thin';
    src: url('/fonts/switzer/Switzer-Thin.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Thin.woff') format('woff'),
         url('/fonts/switzer/Switzer-Thin.ttf') format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-ThinItalic';
    src: url('/fonts/switzer/Switzer-ThinItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-ThinItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Extralight';
    src: url('/fonts/switzer/Switzer-Extralight.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Extralight.woff') format('woff'),
         url('/fonts/switzer/Switzer-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-ExtralightItalic';
    src: url('/fonts/switzer/Switzer-ExtralightItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-ExtralightItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-ExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Light';
    src: url('/fonts/switzer/Switzer-Light.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Light.woff') format('woff'),
         url('/fonts/switzer/Switzer-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-LightItalic';
    src: url('/fonts/switzer/Switzer-LightItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-LightItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('/fonts/switzer/Switzer-Regular.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Regular.woff') format('woff'),
         url('/fonts/switzer/Switzer-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-Italic';
    src: url('/fonts/switzer/Switzer-Italic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Italic.woff') format('woff'),
         url('/fonts/switzer/Switzer-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Medium';
    src: url('/fonts/switzer/Switzer-Medium.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Medium.woff') format('woff'),
         url('/fonts/switzer/Switzer-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-MediumItalic';
    src: url('/fonts/switzer/Switzer-MediumItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-MediumItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Semibold';
    src: url('/fonts/switzer/Switzer-Semibold.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Semibold.woff') format('woff'),
         url('/fonts/switzer/Switzer-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-SemiboldItalic';
    src: url('/fonts/switzer/Switzer-SemiboldItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-SemiboldItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Bold';
    src: url('/fonts/switzer/Switzer-Bold.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Bold.woff') format('woff'),
         url('/fonts/switzer/Switzer-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-BoldItalic';
    src: url('/fonts/switzer/Switzer-BoldItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-BoldItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Extrabold';
    src: url('/fonts/switzer/Switzer-Extrabold.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Extrabold.woff') format('woff'),
         url('/fonts/switzer/Switzer-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-ExtraboldItalic';
    src: url('/fonts/switzer/Switzer-ExtraboldItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-ExtraboldItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-ExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer-Black';
    src: url('/fonts/switzer/Switzer-Black.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-Black.woff') format('woff'),
         url('/fonts/switzer/Switzer-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-BlackItalic';
    src: url('/fonts/switzer/Switzer-BlackItalic.woff2') format('woff2'),
         url('/fonts/switzer/Switzer-BlackItalic.woff') format('woff'),
         url('/fonts/switzer/Switzer-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
  }

  /* #### Generated By: http://www.cufonfonts.com #### */

  @font-face {
    font-family: 'DM Serif Display Regular';
    font-style: normal;
    font-weight: normal;
    src: local('DM Serif Display Regular'), url('/fonts/dm-serif/DMSerifDisplay-Regular.woff') format('woff');
  }
    

  @font-face {
    font-family: 'DM Serif Display Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Serif Display Italic'), url('/fonts/dm-serif/DMSerifDisplay-Italic.woff') format('woff');
  }
  